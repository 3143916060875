<template>
    <div class="page-comp">       

        <!-- Header -->
        <header class="container-lg front-header mt16">
            <div class="logo"><router-link :to="'/'"><img src="@/assets/img/respondi-logo.png" alt="Logo Respondi" /></router-link></div>
            <nav class="nav">
                <ul class="nav-list">
                    <li class="nav-item mr32"><router-link :to="'/#plans'">Preços</router-link></li>
                    <li class="nav-item mr32"><router-link :to="'login'">Entrar</router-link></li>
                    <li class="nav-item button"><Btn :link="'signup'">Criar conta</Btn></li>
                </ul>
            </nav>
        </header>

        <!-- Main -->
        <main class="container-lg front-main mt64">

            <div class="page-header mt128 mb64">
                <h1 class="h1 mb16">Typeform ou Respondi?</h1>
                <h2 class="h2">O Typeform é ótimo, nós adoramos ele. Porém, existe espaço para algo diferente. Por isso criamos uma alternativa ao Typeform, o <strong>Respondi</strong>! Um serviço 100% brasileiro, adaptado a nossa cultura:</h2>
                <div class="demo-image mt32 mb32">
                    <img src="@/assets/img/home/form-demo.gif" alt="Demonstrativo do Respondi">
                </div>
                <p class="mt16">Crie seus formulários, pesquisas ou enquetes rapidamente com o Respondi. Veja as principais diferenças em relação ao Typeform:</p>
                <Btn :href="'https://respondi.app/NNMni5MV/?ref=demo'" type="outline" target="_blank">Veja um exemplo →</Btn>

            </div>

            <div class="separator mb64"></div>

            <section class="content-section invert mt128">
                <div class="text">
                    <h2 class="title">Perguntas exclusivas</h2>
                    <p class="intro mt8 mb64">Finalmente um serviço que entende o que é CPF, CNPJ ou CEP. Acabou a digitação errada.</p>

                    <h3 class="subtitle">CPF e CNPJ</h3>
                    <p>Nosso campo de documento acaba com preenchimento de CPF/CNPJ inválidos, confirmando o formato antes mesmo do envio.</p>            
                    <h3 class="subtitle">Busca por CEP</h3>
                    <p>Preenhcer endereço é sempre chato, né? Resolvemos isso com nosso campo de busca de CEP. Basta informar o CEP, e pronto! Tudo preenchido.</p>                
                    <h3 class="subtitle">Telefone com DDD</h3>
                    <p>"Não esqueça de informar o DDD". Quantas vezes você já colocou isso no seu formulário? Agora não precisa mais. Nosso campo de telefone valida DDDs e evita telefones visivelmente incorretos como "123456789".</p>
                    <router-link :to="'signup'" class="link">Crie sua conta agora →</router-link>
                </div>
                
                <div class="image">
                    <img src="https://i.imgur.com/huUxdaO.gif" alt="">
                </div>


            </section>

            <!-- Section -->
            <section class="content-section mt128">
                <div class="text">
                    <h2 class="title">Inovações que importam</h2>
                    <p class="intro mt8 mb64">Trabalhamos para te economizar tempo, inovando para facilitar o seu dia a dia.</p>

                    <h3 class="subtitle">Integração com WhatsApp</h3>
                    <p>Sempre que um telefone for informado, você poderá entrar em contato direto com a pessoa via WhatsApp.</p>            
                    <h3 class="subtitle">Dados de CNPJ</h3>
                    <p>Quando um CNPJ for inserido, nós já buscamos informações básicas sobre aquela empresa para facilitar sua vida.</p>                
                    <h3 class="subtitle">Tags e visualizações</h3>
                    <p>Precisa fazer uma anotação rápida sobre uma resposta? Use as tags em vez de exportar para o Excel.</p>
                    <router-link :to="'signup'" class="link">Crie sua conta agora →</router-link>
                </div>
                
                <div class="image">
                    <img src="https://i.imgur.com/XTwpKGS.png" alt="">
                </div>

            </section>

            <!-- Section -->
            <section class="content-section invert mt128">
                <div class="text">
                    <h2 class="title">Foco no Brasil</h2>
                    <p class="intro mt8 mb64">Além dos campos de perguntas locais, nós temos diversos outros pontos que favorecem empresas brasileiras.</p>

                    <h3 class="subtitle">Pague com boleto</h3>
                    <p>Não tem cartão corporativo e precisa emitir um boleto bancário? Sem problemas, nós aceitamos.</p>            
                    <h3 class="subtitle">Pagamento em real, não em dólar</h3>
                    <p>Esqueça a variação cambial ou impostos internacionais como o IOF. Sua mensalidade é paga em real, e não fica variando a cada mês.</p>                
                    <h3 class="subtitle">Suporte em português</h3>
                    <p>Do you speak portuguese? Nós falamos! Sempre que precisar de ajuda, estaremos prontos pra te atender na sua lingua..</p>
                    <router-link :to="'signup'" class="link">Crie sua conta agora →</router-link>
                </div>
                
                <div class="image">
                    <img src="https://i.imgur.com/LdEDcd8.png" alt="">
                </div>


            </section>
            
        </main>

        <!-- Footer -->
        <footer class="container-lg front-footer mt128 mb128">
            <div class="content">
                <h2 class="title">Crie sua conta grátis</h2>
                <p class="intro mt8">Comece sem qualquer custo. Se desejar, pode fazer upgrade para um plano pago no futuro.</p>
                <Btn :link="'signup'" class="mt32">Criar minha conta →</Btn>
            </div>
        </footer>
        

    </div>
   
</template>

<script>
import Btn from '../components/form/Btn.vue'
    export default {
  components: { Btn, Btn },
        name: "Page",
          metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Typeform ou Respondi? Qual o melhor?',
            meta: [{property: 'og:image', content: 'https://i.imgur.com/cibqXUI.png'}]
        },
    }
</script>

<style lang="sass" scoped>
.page-comp

    .logo
        max-width: 140px

    .h1
        font-size: $fzXl
        font-weight: bold
    
    .separator
        background: $cBlack
        width: 50px
        height: 6px
        border-radius: 4px
        margin: auto

    .container-lg
        width: 100%
        max-width: 1200px
        margin-left: auto
        margin-right: auto
        padding-left: $mgSm
        padding-right: $mgSm
    

    .front-header
        // background: red
        display: flex
        justify-content: space-between
        align-items: center
        padding: $mgXs 0

        .nav-list
            // font-weight: bold
            display: flex
            list-style: none
            align-items: center

    .front-main
        .title
            font-size: $fzLg

        .subtitle
            font-size: $fzSm
            margin-bottom: $mgXs

        .intro
            font-size: $fzSm * 1.2
            line-height: 140%
            margin-left: auto
            margin-right: auto
        
        p
            font-size: $fzXs
            line-height: 150%
            margin-bottom: $mg

        .page-header    
            text-align: center
            margin: 0 auto
            // display: flex
            // align-items: center
            // flex-direction: column

            .h1
                font-size: $fzXl * 1.2

            .h2
                font-weight: normal
                font-size: $fzSm
                line-height: 150%
            *
                max-width: 600px
                margin-left: auto
                margin-right: auto
            
            .demo-image, .demo-image img
                max-width: 900px

        .content-section            
            display: flex
            align-items: flex-start

            .text
                margin-right: $mgLg
                flex: 1
                font-size: $fzSm
                

            .image
                flex: 1.5

                img
                    border-radius: 10px

            &.invert
                flex-direction: row-reverse

                .text
                    margin-left: $mgLg
                    margin-right: 0

    .front-footer
        padding-bottom: $mgLg * 2
        display: flex
        justify-content: center

        .content
            max-width: 500px
            text-align: center
            
        .title
            font-size: $fzLg

      
    
    

</style>